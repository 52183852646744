import { useParams, Link } from "react-router-dom";
import Club from './Club'

const { REACT_APP_BACKEND_URL } = process.env;

function PlayerCard(props) {
  const { locale } = useParams();
  const toLocale = locale ? `/${locale}` : "";

  return (
    <div className="PlayerCard" key={props.data.id}>
      <Link
        to={encodeURI(`${toLocale}/players/${props.data.attributes.shortName}`)}
        key={props.id}
        className="d-flex align-items-center"
      >
        <img
          height="80"
          src={
            REACT_APP_BACKEND_URL +
            props.data.attributes.picture.data.attributes.url
          }
          alt={""}
          className="PlayerCardImage"
        />
        <div className="PlayerInfo">
          <h4 className="PlayerCard__name">
            {props.data.attributes.shortName}
          </h4>
          <div className="PlayerCard__team">
            <Club club={props.data.attributes.club} />
          </div>
        </div>
      </Link>
    </div>
  );
}

export default PlayerCard;
