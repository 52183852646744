import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TeamMember from "./TeamMember";

const { REACT_APP_BACKEND_URL, REACT_APP_DEFAULT_LOCALE } = process.env;

function Team() {
  const params = useParams();
  const locale = params.locale ?? REACT_APP_DEFAULT_LOCALE;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [info, setInfo] = useState({});

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);

    const url =
      REACT_APP_BACKEND_URL +
      `/api/team?populate=team.picture&locale=${locale}`;
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          setInfo(result.data.attributes);
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [locale, i18n]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <section className="Section-with-vertical-title">
          <div className="container watermark">
            <div>
              <h2>{t("pageTitle.aboutUs")}</h2>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="col Team-about" dangerouslySetInnerHTML={{ __html: info.about }}></div>
              </div>
            </div>
            <div className="Team-container">
              <div className="row">
                {info.team.map((teamMember) => (
                  <TeamMember key={teamMember.id} data={teamMember} />
                ))}
              </div>
            </div>
          </div>
          <div className="VerticalTitle">{t("pageTitle.aboutUs")}</div>
        </section>
      </>
    );
  }
}

export default Team;
