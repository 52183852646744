import React from "react";

const { REACT_APP_BACKEND_URL } = process.env;

function Club(props) {
  const { club } = props;
  return (
    <>
      <div className="ClubLogoWrapper">
        <img
          src={REACT_APP_BACKEND_URL + club?.data?.attributes.logo.data?.attributes.url}
          alt={""}
          className="ClubImage"
        />
      </div>
      <div className="ClubNameWrapper">
        {club?.data?.attributes.name}
      </div>
    </>
  );
}

export default Club;
