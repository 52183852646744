import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FootballField from "./FootballField";
import PlayerCard from "./PlayerCard";
import { groupBy } from "ramda"

const { REACT_APP_BACKEND_URL, REACT_APP_DEFAULT_LOCALE } = process.env;

function PlayerList({ activeContract }) {
  const params = useParams();
  const locale = params.locale ?? REACT_APP_DEFAULT_LOCALE;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [players, setPlayers] = useState([]);
  const [searchParams] = useSearchParams();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);

    let url =
      REACT_APP_BACKEND_URL +
      '/api/players' +
      '?populate[0]=nationality' +
      '&populate[1]=picture' + // so many populations to be able to query club.logo, nothing else worked
      '&populate[2]=career' +
      '&populate[3]=main_foot' +
      '&populate[4]=secondary_foot' +
      '&populate[5]=main_position' +
      '&populate[6]=secondary_positions' +
      '&populate[7]=club.logo' +
      '&pagination[pageSize]=1000' +
      `&locale=${locale}&sort[0]=shortName&filters[activeContract]=${activeContract}`;
    if (searchParams.get("position")) {
      url =
        url +
        `&filters[$or][0][main_position][id][$eq]=${searchParams.get(
          "position"
        )}`;
    }
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          setPlayers(result.data);
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [searchParams, activeContract, locale, i18n]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    const title = activeContract ? t("pageTitle.players") : t("pageTitle.history");

    // groups to object keyed with position order in player list
    // result: { 1: [{}, {}], 3: [{},{}], ... }
    const positionGroups = groupBy(player => player.attributes.main_position?.data?.attributes.playerListOrder, players);

    // convert this to list of list, sice iterating the above object using for ... in does not yield consisntent order across all browsers
    // see https://stackoverflow.com/q/280713 for more info
    // result: [ [1, [{}, {}]], [3, [{}, {}]], ... ]
    const positionGroupEntries = Object.entries(positionGroups);

    const renderPlayers = (indexedGroups) => {
      const result = [];
      for (const indexedGroup of indexedGroups) {
        const groupResult = []
        const index = indexedGroup[0];
        const group = indexedGroup[1];
        groupResult.push(<div className="PlayerListGroup__Title" key={'group_' + index + '_title'}>{group[0].attributes.main_position?.data?.attributes.position}</div>);
        group.map((player) => (
          groupResult.push(<PlayerCard key={player.id} data={player} />)
        ));
        result.push(<div className="PlayerListGroup" key={'group_' + index + '_group'}>{groupResult}</div>);
      }
      return result;
    }

    return (
      <>
        <section className="Section-with-vertical-title">
          <div className="container watermark">
            <div>
              <h2>{title}</h2>
            </div>
            <div className="Player-list-outer-container row align-items-start">
              <div className="col">
                <FootballField activeContract={activeContract} />
              </div>
              <div className="col">
                {renderPlayers(positionGroupEntries)}
              </div>
            </div>
          </div>
          <div className="VerticalTitle">{title}</div>
        </section>

      </>
    );
  }
}

export default PlayerList;
