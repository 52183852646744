function FooterTeamMember(props) {
  return (
    <div className="col-12 col-sm-6">
      <div className="footer__member" key={props.data.id}>
        <div className="FooterTeamMemberInfo">
          <h4>{props.data.name}</h4>
          <p>Phone: {props.data.phone}</p>
          <p>E-mail: {props.data.email}</p>
        </div>
      </div>
    </div>
  );
}

export default FooterTeamMember;
