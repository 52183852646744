import {
  useLocation,
  useParams,
  useSearchParams,
  Link,
} from "react-router-dom";

const { REACT_APP_DEFAULT_LOCALE } = process.env;

function LocaleSwitcher({ localizedPositions }) {
  const supportedLocales = ["en", "cs"];

  const normalizeUrl = (url) => (url.endsWith("/") ? url.slice(0, -1) : url);

  const params = useParams();
  const [searchParams] = useSearchParams();
  const { pathname, search } = useLocation();

  let basePath;
  if (params.locale) {
    basePath = pathname.substring(3);
  } else {
    basePath = pathname;
  }

  // special treatment for positions i.e. when in en locale and we filter for position 1, cs locale must point to its localized sibling, i.e. 2
  const localizePosition = (locale) => {
    if (searchParams.get("position")) {
      if (localizedPositions.length > 1) {
        const position = localizedPositions.find(
          (pos) => pos.id === parseInt(searchParams.get("position"))
        );
        if (position) {
          const result =
            "?" +
            new URLSearchParams({
              ...searchParams,
              position: position.localizations.find(
                (pos) => pos.locale === locale
              ).id,
            }).toString();
          return result;
        } else {
          return search;
        }
      }
    } else {
      return search;
    }
  };

  return (
    <>
      {supportedLocales.map((locale) => {
        if (locale !== (params.locale ?? REACT_APP_DEFAULT_LOCALE)) {
          return (
            <Link
              key={locale}
              to={
                (locale === REACT_APP_DEFAULT_LOCALE
                  ? basePath
                  : normalizeUrl("/" + locale + basePath)) +
                localizePosition(locale)
              }
            >
              {locale}
            </Link>
          );
        }
        return null;
      })}
    </>
  );
}

export default LocaleSwitcher;
