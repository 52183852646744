import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import App from "./App";
import Homepage from "./Homepage";
import PlayerList from "./PlayerList";
import Team from "./Team";
import Services from "./Services";
import PlayerDetail from "./PlayerDetail";
import TransferList from "./TransferList";
import Scouting from "./Scouting";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import SimpleReactLightbox from 'simple-react-lightbox'

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.js';

ReactDOM.render(
  <SimpleReactLightbox>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Homepage />} />
          <Route path="/:locale" element={<Homepage />} />
          <Route
            exact
            path="/players"
            element={<PlayerList activeContract={true} />}
          />
          <Route
            exact
            path="/:locale/players"
            element={<PlayerList activeContract={true} />}
          />
          <Route exact path="/players/:name" element={<PlayerDetail />} />
          <Route exact path="/:locale/players/:name" element={<PlayerDetail />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/:locale/team" element={<Team />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/:locale/services" element={<Services />} />
          <Route exact path="/transfers" element={<TransferList />} />
          <Route exact path="/:locale/transfers" element={<TransferList />} />
          <Route exact path="/scouting" element={<Scouting />} />
          <Route exact path="/:locale/scouting" element={<Scouting />} />
          <Route exact path="/scouting/:country" element={<Scouting />} />
          <Route exact path="/:locale/scouting/:country" element={<Scouting />} />
          <Route
            exact
            path="/history"
            element={<PlayerList activeContract={false} />}
          />
          <Route
            exact
            path="/:locale/history"
            element={<PlayerList activeContract={false} />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </SimpleReactLightbox>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
