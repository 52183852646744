import Club from './Club'

const { REACT_APP_BACKEND_URL } = process.env;

function TransferCard({ data, compact }) {
  if (compact)
    return (
      <div className="TransferCard--compactwrapper">
        <h5>{data.attributes.name}</h5>
        <div className="TransferCard__compactimage">
          <img
            src={
              REACT_APP_BACKEND_URL +
              data.attributes.picture.data.attributes.url
            }
            alt={""}
          />
        </div>
        <div className="row TransferCard__clubinfo">
          <div className="col-5">
            <Club club={data.attributes.club_from} />
          </div>
          <div className="col-2 d-flex align-items-center">
            ➔
          </div>
          <div className="col-5">
            <Club club={data.attributes.club_to} />
          </div>
        </div>
      </div>
    )
  else
    return (
      <div className="h-100 TransferCardShadow TransferCard--wrapper">
        <div className="TransferCard" key={data.id}>
          <div className="TransferCard__image">
            <img
              src={
                REACT_APP_BACKEND_URL +
                data.attributes.picture.data.attributes.url
              }
              alt={""}
            />
          </div>
          <div className="TransferInfo pt-4 ps-4 pe-4">
            <h4>{data.attributes.name}</h4>
            <div className="row TransferCard__clubinfo">
              <div className="col-3">
                <Club club={data.attributes.club_from} />
              </div>
              <div className="col-1 d-flex align-items-center">
                ➔
              </div>
              <div className="col-3">
                <Club club={data.attributes.club_to} />
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <div dangerouslySetInnerHTML={{ __html: data.attributes.text }}></div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default TransferCard;
