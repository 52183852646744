import { useEffect } from "react";
import CountUp from 'react-countup';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const COUNTER_DURATION = 1;
const { REACT_APP_DEFAULT_LOCALE } = process.env;

function Counters({ data }) {
  const params = useParams();
  const locale = params.locale ?? REACT_APP_DEFAULT_LOCALE;

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale, i18n]);

  return (
    <div className="row Counters gx-5" style={{ backgroundColor: '#E8E8E8', paddingBottom: '30px' }}>
      <div className="row col-12 col-md-6">
        <div className="col-6">
          <CountUp end={new Date().getFullYear() - data.onMarketSinceYear} duration={COUNTER_DURATION} className="Counters__number" />
          <div className="Counters__text">{t("counters.onMarketSinceYear")}</div>
        </div>
        <div className="col-6">
          <CountUp end={data.scoutedCountries} duration={COUNTER_DURATION} className="Counters__number" />
          <div className="Counters__text">{t("counters.scoutedCountries")}</div>
        </div>
      </div>
      <div className="row col-12 col-md-6">
        <div className="col-6">
          <CountUp end={data.transfersMade} duration={COUNTER_DURATION} className="Counters__number" />
          <div className="Counters__text">{t("counters.transfersMade")}</div>
        </div>
        <div className="col-6">
          <CountUp end={data.internationalPartnersCount} duration={COUNTER_DURATION} className="Counters__number" />
          <div className="Counters__text">{t("counters.internationalPartnersCount")}</div>
        </div>
      </div>
    </div>
  );
}

export default Counters;
