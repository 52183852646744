import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const { REACT_APP_DEFAULT_LOCALE } = process.env;

function formatFromTo(from, to, locale) {
  return `
    ${from.getDate()}${locale === "en" ? "" : "."}
    ${from.getMonth() === to.getMonth() ? "" : from.toLocaleString(locale, { month: 'long' })}
    -
    ${to.getDate()}${locale === "en" ? "" : "."}
    ${to.toLocaleString(locale, { month: 'long', year: 'numeric' })}
  `;
}

function ScoutingSummary({ data }) {
  const params = useParams();
  const locale = params.locale ?? REACT_APP_DEFAULT_LOCALE;

  const { t, i18n } = useTranslation();

  const getIcon = (icon) => {
    switch (icon) {
      case 'airplane':
        return '✈️';
      case 'car':
        return '🚗';
      case 'ship':
        return '🚢';
      default:
        return '🚲';
    }
  }

  const renderTravel = (number, icon) => {
    if (number) {
      return <>{number.toLocaleString(locale)}{getIcon(icon)}</>
    }
  }

  useEffect(() => {
    i18n.changeLanguage(locale);
  },[i18n, locale]);

  return (
    <table className="table">
      <tbody>
        <tr>
          <td className="fw-bold" style={{ "text-transform": "uppercase" }}>{data.country.data.attributes.name}</td>
          <td>{formatFromTo(new Date(data.from), new Date(data.to), locale)}</td>
        </tr>
        <tr>
          <td className="fw-bold">{t("scouting.numberOfDays")}</td>
          <td>{(new Date(data.to) - new Date(data.from)) / (1000 * 3600 * 24) + 1}</td>
        </tr>
        <tr>
          <td className="fw-bold">{t("scouting.numberOfMatches")}</td>
          <td>{data.matchesCount}</td>
        </tr>
        <tr>
          <td className="fw-bold">{t("scouting.bestMatch")}</td>
          <td>{data.bestMatch}</td>
        </tr>
        <tr>
          <td className="fw-bold">{t("scouting.bestPlayer")}</td>
          <td>{data.bestPlayer}</td>
        </tr>
        <tr>
          <td className="fw-bold">{t("scouting.highestVisit")}</td>
          <td>{data.highestVisitMeters?.toLocaleString(locale)} {data.highestVisitText}</td>
        </tr>
        <tr>
          <td className="fw-bold">{t("scouting.numberOfKm")}</td>
          <td>{
            ((data.travelledKmAirplane ?? 0) +
              (data.travelledKmCar ?? 0) +
              (data.travelledKmShip ?? 0) +
              (data.travelledKmOther ?? 0)).toLocaleString(locale)
          } ({renderTravel(data.travelledKmAirplane, 'airplane')}
            {renderTravel(data.travelledKmCar, 'car')}
            {renderTravel(data.travelledKmShip, 'ship')}
            {renderTravel(data.travelledKmOther)})
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default ScoutingSummary;