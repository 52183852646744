import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Http from 'i18next-http-backend'; // have a own http fallback
import BackendAdapter from 'i18next-multiload-backend-adapter';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const { REACT_APP_BACKEND_URL, REACT_APP_DEFAULT_LOCALE } = process.env;

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(BackendAdapter)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: REACT_APP_DEFAULT_LOCALE,
    debug: false,

    react: {
      useSuspense: false
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      backend: Http,
      backendOption: {
        allowMultiLoading: true,
        loadPath: REACT_APP_BACKEND_URL + '/api/translations?pagination[pageSize]=1000',
        parse: function (data) {
          const parsedData = JSON.parse(data);
          return parsedData.data.reduce((result, translate) => {
            result.en.translation[translate.attributes.key] = translate.attributes['en'];
            result.cs.translation[translate.attributes.key] = translate.attributes['cs'];
            return result;
          }, { en: { translation: {} }, cs: { translation: {} } });
        }
      }
    }
  });


export default i18n;