import Club from './Club'
import { useParams, Link } from "react-router-dom";

const { REACT_APP_BACKEND_URL } = process.env;

function HeroPlayer({ player }) {
  const { locale } = useParams();
  const toLocale = locale ? `/${locale}` : "";

  return (
    <Link
      to={encodeURI(`${toLocale}/players/${player.shortName}`)}
      key={player.id}
    >
      <div className="row g-0 align-items-end">
        <div className="col-md col-md-5 offset-md-1">
          <img
            src={
              REACT_APP_BACKEND_URL +
              player.heroPicture.data?.attributes.url
            }
            alt={""}
            className="img-fluid"
          />
        </div>
        <div className="col-md text-center HeroPlayer">
          <div className="HeroPlayer__name text-nowrap">
            {player.shortName}
          </div>
          <Club club={player.club} />
        </div>
      </div>
    </Link>
  );
}

export default HeroPlayer;
