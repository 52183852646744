const { REACT_APP_BACKEND_URL } = process.env;

function Service({ service }) {
  return (
    <div className="row mb-3 ScoutingItem">
      <div className="col-md-3 col-12">
        <img
          src={
            REACT_APP_BACKEND_URL +
            service.icon.data.attributes.formats.thumbnail.url
          }
          alt="icon"
          width="100%"
        />
      </div>
      <div className="col-md-9 col-12"><h3>{service.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: service.fulltext }}></div>
      </div>
    </div>
  );
}

export default Service;
