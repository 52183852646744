import World from "./World";
import React, { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScoutingItem from "./ScoutingItem";
import ScoutingFilter from "./ScoutingFilter";

const { REACT_APP_BACKEND_URL, REACT_APP_DEFAULT_LOCALE } = process.env;

function Scouting() {
  const params = useParams();
  const refLocale = useRef();
  const locale = params.locale ?? REACT_APP_DEFAULT_LOCALE;
  const refCountry = useRef();
  const country = params.country;
  const refScoutingItems = useRef(null);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [scoutings, setScoutings] = useState([]);

  const refSeason = useRef();
  const [searchParams] = useSearchParams();
  const season = searchParams.get("season");

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { t, i18n } = useTranslation();

  const countryClickHandler = () => {
    refScoutingItems.current?.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    i18n.changeLanguage(locale);

    if (refLocale.current !== locale || refCountry.current !== country || refSeason.current !== season) {
      setPage(1);
    }

    let url =
      REACT_APP_BACKEND_URL +
      `/api/scoutings?populate=*&sort[0]=date:desc&pagination[page]=${page}&pagination[pageSize]=5&locale=${locale}`;
    if (country && country.length === 2) {
      url += `&filters[country][code][$eq]=${country}`;
    }
    if (season) {
      url += `&filters[season][$eq]=${season}`;
    }
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          (refLocale.current === locale && refCountry.current === country && refSeason.current === season)
            ? setScoutings((prev) => [...prev, ...result.data])
            : setScoutings(result.data);
          refLocale.current = locale;
          refCountry.current = country;
          refSeason.current = season;

          setPageCount(result.meta.pagination.pageCount)
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [locale, country, page, i18n, season]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <section className="Section-with-vertical-title">
        <div className="container">
          <div>
            <h2>{t("pageTitle.scouting")}</h2>
          </div>
          <div className="row">
            <World  zoom={true} countryClickHandler={countryClickHandler} />
          </div>
          {/* Ukazat filter sezon jen kdyz neni zafiltrovana zeme */}
          {!country && <div className="col-md-6">
            <ScoutingFilter />
          </div>
          }
          <div className="row watermark" ref={refScoutingItems} style={{ scrollMarginTop: "80px" }}>
            {scoutings.map((scouting) => (
              <ScoutingItem key={scouting.id} data={scouting.attributes} />
            ))}
          </div>
          {page < pageCount &&
            <div className="row col">
              <button type="button" class="btn btn-light" onClick={() => setPage(prev => prev + 1)}>Nahrát více</button>
            </div>
          }
        </div>
        <div className="VerticalTitle">{t("pageTitle.scouting")}</div>
      </section>
    );
  }
}

export default Scouting;
