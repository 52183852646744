import HeroPlayer from "./HeroPlayer";
import Carousel from 'react-bootstrap/Carousel';

const { REACT_APP_BACKEND_URL } = process.env;

function HeroPlayers({ players, logo }) {
  return (
    <div class="row g-0 align-items-center">
      <Badge logo={logo} />
      <PlayerCarousel players={players} />
    </div>
  )
}

const Badge = ({ logo }) => (
  <div className="col-md order-md-last text-center">
    <img
      src={REACT_APP_BACKEND_URL + logo.data.attributes.url}
      alt="Logo"
      width="40%"
      style={{ filter: "brightness(10)" }}
    />
    <div className="text-nowrap LogoBadge__CompanyName">Bicycle Kick</div>
    <div className="LogoBadge__CompanySubtitle">Football Scouting</div>
  </div>
);

const PlayerCarousel = ({ players }) => (
  <Carousel
    pause={false}
    controls={false}
    indicators={false}
    interval={3000}
    className="col-md-8">
    {
      players?.map(player =>
        <Carousel.Item>
          <HeroPlayer player={player.attributes} />
        </Carousel.Item>)
    }
  </Carousel>
)

export default HeroPlayers;
