import React from "react";

const { REACT_APP_BACKEND_URL } = process.env;

function TeamMember(props) {
  return (
    <div className="col-12 col-sm-6">
      <div className="TeamMember ms-2" key={props.data.id}>
        <img
          src={REACT_APP_BACKEND_URL + props.data.picture.data.attributes.url}
          alt={""}
        />
        <div className="TeamMemberInfo">
          <h4 className="mt-3">{props.data.name}</h4>
          <p className="TeamMember__info" dangerouslySetInnerHTML={{ __html: props.data.description }} />
        </div>
      </div>
    </div>
  );
}

export default TeamMember;
