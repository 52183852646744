import { SRLWrapper } from "simple-react-lightbox";
import { useParams } from "react-router-dom";
import ScoutingSummary from "./ScoutingSummary";
import SignalThumbnails from "./SignalThumbnails";
import SimpleReactLightbox from 'simple-react-lightbox'

const { REACT_APP_DEFAULT_LOCALE } = process.env;

function ScoutingItem({ data }) {
  const params = useParams();
  const locale = params.locale ?? REACT_APP_DEFAULT_LOCALE;

  return (
    <div className="container ScoutingItem">
      <div className="row">
        <h2>{data.title}</h2>
      </div>
      <div className="row">
        <div className="Scouting-date"><i className="bi bi-calendar"></i> {new Date(data.date).toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' })}</div>
      </div>
      <div className="row">
        <div dangerouslySetInnerHTML={{ __html: data.article }}></div>
      </div>
      <div className="row">
        <div className="col-6">
          <ScoutingSummary data={data} />
        </div>
        <div className="col-6 ThumbnailsWrapper">
          <SimpleReactLightbox>
            <SRLWrapper options={{
              settings: {
                autoplaySpeed: 0,
                slideAnimationType: 'slide'
              }, buttons: {
                showDownloadButton: false,
                showFullscreenButton: false,
                showThumbnailsButton: false
              }
            }}>
              <SignalThumbnails images={data.images} />
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
      </div>
    </div>
  );
}

export default ScoutingItem;
