/* eslint-disable react/jsx-no-target-blank */
import { useState } from "react";
import LocaleSwitcher from "./LocaleSwitcher";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import menuOpen from "./assets/images/menu-open.svg";
import menuClose from "./assets/images/menu-close.svg";

const { REACT_APP_BACKEND_URL } = process.env;

function Navbar({ data, localizedPositions, offset }) {
  const { locale } = useParams();
  const toLocale = locale ? `/${locale}` : "";

  const [navigationActive, setNavigationActive] = useState(false);
  const [menuImage, setMenuImage] = useState('open');

  const normalizeUrl = (url) => (url.endsWith("/") ? url.slice(0, -1) : url);

  const openMenu = () => {
    setNavigationActive(true);
    setMenuImage('close');
  }

  const closeMenu = () => {
    setNavigationActive(false);
    setMenuImage('open');
  }

  const headerClassName = offset > 30 ? "header header__fixed" : "header";

  return (
    <>
      <div className="social-bar">
        <a href="https://twitter.com/bicyclekick1" target="_blank"><i className="bi bi-twitter"></i></a>
        <a href="https://www.facebook.com/Bicycle-Kick-football-scouting-546299309159178" target="_blank"><i className="bi bi-facebook"></i></a>
        <a href="https://www.instagram.com/bicyclekickfootball/" target="_blank"><i className="bi bi-instagram"></i></a>
        <a style={{ paddingLeft: 16 }} href="https://www.transfermarkt.com/bicycle-kick-football-scouting/beraterfirma/berater/7931" target="_blank"><img style={{ verticalAlign: "text-top" }}src="https://tmsi.akamaized.net/head/transfermarkt_logo.svg" alt="Transfermarkt" width="70" height="27"></img></a>
      </div>
      <header className={headerClassName} style={{ backgroundColor: '#E8E8E8' }}>
        <div className="header__inner">
          <Link className="header__logo" to={normalizeUrl(toLocale + "/")}>
            <img
              src={REACT_APP_BACKEND_URL + data.logo.data.attributes.url}
              alt="Logo"
            />
          </Link>
          <nav className={`navigation${navigationActive ? ' active' : ''}`}>
            {data.links.map((link) => {
              return (
                <Link key={link.url} to={normalizeUrl(toLocale + link.url)} onClick={() => closeMenu()}>
                  {link.text}
                </Link>
              );
            })}
            <LocaleSwitcher localizedPositions={localizedPositions} />
          </nav>
          <div className="header__menu">
            <div className={`header__open ${menuImage === 'open' && 'active'}`} onClick={() => openMenu()}>
              <img src={menuOpen} alt='Open menu'></img>
            </div>
            <div className={`header__close ${menuImage === 'close' && 'active'}`} onClick={() => closeMenu()}>
              <img src={menuClose} alt='Close menu'></img>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Navbar;
