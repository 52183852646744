import React, { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import TransferCard from "./TransferCard";
import TransferListFilter from "./TransferListFilter";
import { useTranslation } from "react-i18next";

const { REACT_APP_BACKEND_URL, REACT_APP_DEFAULT_LOCALE } = process.env;

function TransferList({ compact }) {
  const params = useParams();
  const refLocale = useRef();
  const locale = params.locale ?? REACT_APP_DEFAULT_LOCALE;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [transfers, setTransfers] = useState([]);
  const [searchParams] = useSearchParams();

  const refSeason = useRef();
  const season = searchParams.get("season");

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);

    if (refLocale.current !== locale || refSeason.current !== season) {
      setPage(1);
    }

    let url = REACT_APP_BACKEND_URL + `/api/transfers?populate=picture&populate=club_from.logo&populate=club_to.logo&sort=season:desc&locale=${locale}`;
    if (season) {
      url +=
        `&filters[0][season][$eq]=${season}`
    }
    if (compact) {
      url += `&pagination[limit]=${compact}`;
    } else {
      url += `&pagination[page]=${page}&pagination[pageSize]=10`
    }

    fetch(
      url
    )
      .then((res) => res.json())
      .then(
        (result) => {
          (refLocale.current === locale && refSeason.current === season)
            ? setTransfers((prev) => [...prev, ...result.data])
            : setTransfers(result.data);

          refLocale.current = locale;
          refSeason.current = season;

          setPageCount(result.meta.pagination.pageCount)
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [locale, i18n, searchParams, compact, page, season]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else if (compact) {
    return (
      <div>
        <div className="container d-flex-column">
          <div className="row" style={{ "text-align": "center" }}>
            <h2>{t("title.lastTransfers")}</h2>
          </div>
        </div>
        <div className="container d-flex-column">
          <div className="row">
            {transfers.map((transfer) => (
              <div className="col-md-6">
                <TransferCard key={transfer.id} data={transfer} compact={true} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <section className="Section-with-vertical-title">
        <div className="container watermark">
          <div className="row">
            <div className="col-md-6">
              <h2>{t("pageTitle.transfers")}</h2>
              <TransferListFilter />
            </div>
          </div>
          <div className="row">
            {transfers.map((transfer) => (
              <div className="col-md-6 mt-4">
                <TransferCard key={transfer.id} data={transfer} />
              </div>
            ))}
          </div>
          {page < pageCount &&
            <div className="row mt-4">
              <div class="d-grid">
                <button type="button" class="btn btn-light" onClick={() => setPage(prev => prev + 1)}>Nahrát více</button>
              </div>
            </div>
          }
        </div>
        <div className="VerticalTitle">{t("pageTitle.transfers")}</div>
      </section>
    );
  }
}

export default TransferList;
