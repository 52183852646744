import React, { useState, useEffect } from "react";
import { useOutletContext, useParams, Link } from "react-router-dom";

const { REACT_APP_BACKEND_URL, REACT_APP_DEFAULT_LOCALE } = process.env;

function FootballField({ activeContract }) {
  const params = useParams();
  const locale = params.locale ?? REACT_APP_DEFAULT_LOCALE;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [positions, setPositions] = useState([]);

  const [, setLocalizedPositions] = useOutletContext();

  useEffect(() => {
    fetch(
      REACT_APP_BACKEND_URL +
        `/api/positions-for-field?activeContract=${activeContract}&locale=${locale}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setPositions(result);
          setLocalizedPositions(result);
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [activeContract, locale, setLocalizedPositions]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <svg height="100%" width="100%" viewBox="0 0 452 684" className="Football-field">
        <defs>
          <g id="occupiedPosition" className="occupiedPosition">
            <circle
              cx="0"
              cy="0"
              r="15"
              fill="#ccc"
              stroke="#888"
              strokeWidth="5"
              className="position-inner"
            />
          </g>
          <g id="unoccupiedPosition" className="unoccupiedPosition">
            <circle
              cx="0"
              cy="0"
              r="15"
              fill="#999"
              stroke="#888"
              className="position-inner"
            />
          </g>
        </defs>
        <rect opacity=".7" height="684" width="452" fill="#4a545e" ry="6" />
        <g stroke="#efe" strokeWidth="3" fill="none">
          <path d="m11.22 22.62v638.8h429.6v-638.8z" />
          <path d="m11.26 342h429.4" />
          <circle cy="342" cx="226" r="54.8" />
          <circle cy="342" cx="226" r="2" />
          <g id="a">
            <path d="m9.9 30.07c4.85 0 8.82-4 8.82-8.9m162.5 100.8a54.91 54.91 0 0 0 89.6 0m76.3-99.63v99.63h-242.2l.2-99.63m98.6.20v-15.6l44.6.003v15.6m-77.9-.20v34.4h111.2v-34.4m160.5 7.7c-4.9 0-8.8-4-8.8-8.9" />
            <circle cy="90" cx="226" r="2" />
          </g>
          <use href="#a" transform="scale(1,-1)" y="-684" />
        </g>
        {positions.map((position) => {
          const gKey = "g" + position.id;
          const aKey = "a" + position.id;
          const posKey = "pos" + position.id;
          const textKey = "text" + position.id;
          if (position.playerExists) {
            return (
              <Link key={aKey} to={"?position=" + position.id}>
                <use
                  key={posKey}
                  x={position.x}
                  y={position.y}
                  href="#occupiedPosition"
                />
                <text
                  key={textKey}
                  x={position.x}
                  y={position.y + 27}
                  dominantBaseline="middle"
                  textAnchor="middle"
                >
                  {position.position}
                </text>
              </Link>
            );
          } else {
            return (
              <g key={gKey}>
                <use
                  key={posKey}
                  x={position.x}
                  y={position.y}
                  href="#unoccupiedPosition"
                />
                <text
                  key={textKey}
                  x={position.x}
                  y={position.y + 27}
                  dominantBaseline="middle"
                  textAnchor="middle"
                >
                  {position.position}
                </text>
              </g>
            );
          }
        })}
      </svg>
    );
  }
}

export default FootballField;
