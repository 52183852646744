const { REACT_APP_BACKEND_URL } = process.env;

// first to images visible, rest hidden, for the lightbox to display them all
const SignalThumbnails = ({ images }) => {
  return (
    <div className="d-flex flex-wrap">
      {
        images.data?.map((image, index) => (
          <div className="ThumbnailsWrapper">
            <a href={REACT_APP_BACKEND_URL + image.attributes.url} key={image.attributes.hash}>
              <img hidden={index > 4} src={REACT_APP_BACKEND_URL + image.attributes.formats.thumbnail.url} alt='' className="img-thumbnail" />
            </a>
            {
              images.data?.length > 5 && index === 4 && <div className="CenteredThumbnailText">+{(images.data?.length) - 5}</div>
            }
          </div>
        ))
      }
    </div>
  )
}

export default SignalThumbnails;