import React, { useState, useEffect } from "react";
import FooterTeamMember from "./FooterTeamMember";

const { REACT_APP_BACKEND_URL } = process.env;

function Footer() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    fetch(REACT_APP_BACKEND_URL + "/api/team?populate=team.picture")
      .then((res) => res.json())
      .then(
        (result) => {
          setTeamMembers(result.data.attributes.team);
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <footer className="footer">
          <div className="container d-flex-column">
            <div className="row">
              {teamMembers.map((teamMember) => (
                <FooterTeamMember key={teamMember.id} data={teamMember} />
              ))}
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
