import './Embed.css';
import React, { useState } from 'react';
import DOMPurify from "dompurify";
import Modal from 'react-bootstrap/Modal';

function Embed({ data }) {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  let clean = DOMPurify.sanitize(data.html, { ADD_TAGS: ['iframe'], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] });

  return (
    <>
      <div className="row col-md-10 offset-md-1 mt-5 mb-5">
        <div className="Embed-main" dangerouslySetInnerHTML={{ __html: clean }} />
      </div>

      <Modal show={show} onHide={handleClose} fullscreen={false} centered contentClassName="Modal-content" size="lg">
        <Modal.Body>
          <div className="Embed-main" dangerouslySetInnerHTML={{ __html: clean }} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Embed;
