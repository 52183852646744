import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const { REACT_APP_BACKEND_URL, REACT_APP_DEFAULT_LOCALE } = process.env;

function TransferListFilter() {
  const params = useParams();
  const locale = params.locale ?? REACT_APP_DEFAULT_LOCALE;

  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [transferSeasons, setTransferSeasons] = useState([]);

  const [searchParams] = useSearchParams();
  const [currentSeason, setCurrentSeason] = useState(searchParams.get("season") ?? '');

  const { t } = useTranslation();

  const onChange = (e) => {
    const selectedSeason = e.target.value;
    setCurrentSeason(selectedSeason);
    if (selectedSeason)
      navigate('?season=' + selectedSeason);
    else
      navigate('');
  }

  useEffect(() => {
    fetch(
      REACT_APP_BACKEND_URL + `/api/transfer-seasons?locale=${locale}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          // unique sorted list of seasons without nulls
          setTransferSeasons(result);
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [locale]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <select className="form-select" value={currentSeason} onChange={e => onChange(e)}>
        <option value=''>{t("transfers.filter.showSeason")}</option>
        {transferSeasons.map((season) => (
          <option key={`season_switch_${season}`} value={season}>{season}</option>
        ))
        }
      </select>
    );
  }
}

export default TransferListFilter;
