import "./main.scss";
import React, { useState, useEffect } from "react";
import { useParams, Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

const { REACT_APP_BACKEND_URL, REACT_APP_DEFAULT_LOCALE } = process.env;

function App() {
  const params = useParams();
  const locale = params.locale ?? REACT_APP_DEFAULT_LOCALE;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [global, setGlobal] = useState([]);

  const [localizedPositions, setLocalizedPositions] = useState([]);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    fetch(
      REACT_APP_BACKEND_URL +
      `/api/global?populate=navbar.logo&populate=navbar.links&locale=${locale}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setGlobal(result.data.attributes);
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [locale]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <Navbar
          data={global.navbar}
          localizedPositions={localizedPositions}
          offset={offset}
        />
        <Outlet context={[localizedPositions, setLocalizedPositions, global.navbar.logo]} />
        <Footer />
      </>
    );
  }
}

export default App;
