import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Service from "./Service";

const { REACT_APP_BACKEND_URL, REACT_APP_DEFAULT_LOCALE } = process.env;

function Services() {
  const params = useParams();
  const locale = params.locale ?? REACT_APP_DEFAULT_LOCALE;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [services, setServices] = useState([]);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);

    const url =
      REACT_APP_BACKEND_URL +
      `/api/services?populate=*&locale=${locale}`;
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          setServices(result.data);
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [locale, i18n]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    const title = t("pageTitle.services");
    return (
      <section className="Section-with-vertical-title">
        <div className="container watermark">
          <div>
            <h2>{title}</h2>
          </div>
            {services.map((service) => (
              <Service key={service.id} service={service.attributes} />
            ))}
        </div>
        <div className="VerticalTitle">{t("pageTitle.services")}</div>
      </section>
    );
  }
}

export default Services;
