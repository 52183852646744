import Embed from './strapi-components/Embed'

function ComponentSwitch({ data }) {
  return data.map(component => {
    switch (component.__component) {
      case 'embed.embed':
        return <Embed key={component.id} data={component} />
      default:
        return <></>
    }
  })
}

export default ComponentSwitch;
