import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import tmlogo from "./transfermarkt_logo.svg";
import { useTranslation } from "react-i18next";
import Club from './Club'

const { REACT_APP_BACKEND_URL, REACT_APP_DEFAULT_LOCALE } = process.env;

const PlayerDetail = () => {
  const params = useParams();
  const name = params.name;
  const locale = params.locale ?? REACT_APP_DEFAULT_LOCALE;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [player, setPlayer] = useState({});
  const [showProfile, setShowProfile] = useState(true);

  const { t, i18n } = useTranslation();

  const handleSwitchClick = (table, e) => {
    e.preventDefault();
    setShowProfile(table === "Profile");
  };

  useEffect(() => {
    i18n.changeLanguage(locale);

    fetch(
      REACT_APP_BACKEND_URL +
      '/api/players' +
      '?populate[0]=nationality' +
      '&populate[1]=picture' + // so many populations to be able to query club.logo, nothing else worked
      '&populate[2]=career' +
      '&populate[3]=main_foot' +
      '&populate[4]=secondary_foot' +
      '&populate[5]=main_position' +
      '&populate[6]=secondary_positions' +
      '&populate[7]=club.logo' +
      '&populate[8]=career.club.logo' +
      `&filters[shortName][$eq]=${name}&locale=${locale}`
    )
      .then((res) => res.json())
      .then(
        ({ data: players }) => {
          setPlayer(players[0]);
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [name, locale, i18n]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    const getAge = (birthDate) =>
      Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

    return (
      <section className="section-detail">
        <div className="container watermark">
          <div className="row">
            <div className="col-12 col-sm-6">
              <img
                src={
                  REACT_APP_BACKEND_URL +
                  player.attributes.picture.data.attributes.url
                }
                alt={""}
                className="img-fluid"
              />
            </div>
            <div className="col-12 col-sm-6">
              <div className="PlayerInfo">
                <h4>
                  {player.attributes.fullName}
                </h4>
                <div className="switcher">
                  <span
                    className="switch"
                    onClick={(e) => handleSwitchClick("Profile", e)}
                  >
                    {t("playerDetail.profile")}
                  </span>
                  &nbsp;|&nbsp;
                  <span
                    className="switch"
                    onClick={(e) => handleSwitchClick("Career", e)}
                  >
                    {t("playerDetail.career")}
                  </span>
                </div>
                {showProfile && (
                  <div>
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="fw-bold">{t("playerDetail.team")}</td>
                          <td>
                            <Club club={player.attributes.club} />
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold">
                            {t("playerDetail.height")}
                          </td>
                          <td>{player.attributes.height} cm</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">{t("playerDetail.born")}</td>
                          <td>
                            {player.attributes.born} (
                            {getAge(player.attributes.born)})
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold">
                            {t("playerDetail.nationality")}
                          </td>
                          <td>
                            {player.attributes.nationality.data?.attributes.name}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold">{t("playerDetail.foot")}</td>
                          <td>
                            {player.attributes.main_foot.data.attributes.foot}
                          </td>
                        </tr>
                        {player.attributes.secondary_foot.data && (
                          <tr>
                            <td className="fw-bold">
                              {t("playerDetail.weakFoot")}
                            </td>
                            <td>
                              {
                                player.attributes.secondary_foot.data.attributes
                                  .foot
                              }
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className="fw-bold">
                            {t("playerDetail.position")}
                          </td>
                          <td>
                            {
                              player.attributes.main_position?.data?.attributes
                                ?.position
                            }
                          </td>
                        </tr>
                        {player.attributes.secondary_positions.data?.length > 0 && (
                          <tr>
                            <td className="fw-bold">
                              {t("playerDetail.otherPositions")}
                            </td>
                            <td>
                              {player.attributes.secondary_positions.data.map(
                                (position) => {
                                  return <p>{position.attributes.position}</p>;
                                }
                              )}
                            </td>
                          </tr>
                        )}
                        {player.attributes.info?.length > 0 &&
                          <tr>
                            <td colSpan="2">
                              <div dangerouslySetInnerHTML={{ __html: player.attributes.info }}></div>
                            </td>
                          </tr>
                        }
                        <tr>
                          <td colSpan="2">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={player.attributes.transfermarktUrl}
                            >
                              <img width="60" src={tmlogo} alt="tmlogo" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {!showProfile && (
                  <div>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">{t("playerDetail.season")}</th>
                          <th scope="col">{t("playerDetail.team")}</th>
                          <th colspan="3" scope="col">{t("playerDetail.stats")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {player.attributes.career.map((career) => {
                          return (
                            <tr key={`carrier_${career.id}`}>
                              <td>{career.season}</td>
                              <td><Club club={career.club} /></td>
                              <td>{t("playerDetail.matches")}: {career.matches ?? 0}</td>
                              <td>{t("playerDetail.goals")}: {career.goals ?? 0}</td>
                              <td>{t("playerDetail.assists")}: {career.assists ?? 0}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default PlayerDetail;
