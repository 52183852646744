import React, { useState, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeroPlayers from "./HeroPlayers";
import Highlight from "./Highlight";
import Counters from "./Counters";
import ComponentSwitch from "./ComponentSwitch";
import TransferList from "./TransferList";
import VisibilitySensor from 'react-visibility-sensor';
import World from "./World";

// import Swiper core and required modules
import { Navigation, Pagination, A11y, Autoplay } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const { REACT_APP_BACKEND_URL, REACT_APP_DEFAULT_LOCALE } = process.env;

function Homepage() {
  const params = useParams();
  const locale = params.locale ?? REACT_APP_DEFAULT_LOCALE;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [home, setHome] = useState([]);

  const [, , logo] = useOutletContext();

  const { t } = useTranslation();

  useEffect(() => {
    fetch(REACT_APP_BACKEND_URL + `/api/home?populate=heroPlayers.heroPicture&populate=heroPlayers.club.logo&populate=highlights&populate=contentZone&locale=${locale}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setHome(result.data.attributes);
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [locale]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        {/* <div style={{position: "fixed", "z-index": -99, width: "100%", height: "100%"}}>
          <iframe
            title="title"
            frameborder="0"
            height="100%"
            width="100%"
            src="https://youtube.com/embed/G0u7UBtY7zg?autoplay=1&controls=0&showinfo=0&autohide=1"
          >
          </iframe>
        </div> */}

        {/* <div className="videoWrapper">
          <iframe title="title" id="ytplayer" type="text/html" width="100%" height="100%"
            src="https://www.youtube.com/embed/G0u7UBtY7zg?controls=0&autoplay=1"
            frameborder="0"></iframe>
        </div> */}

        <div className="HeroPlayers">
          <HeroPlayers players={home.heroPlayers?.data} logo={logo} />
        </div>

        <div className="row">
          <div className="d-flex-column">

            <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
              {({ isVisible }) => (
                <div style={{ minHeight: 100 }}>
                  {isVisible ? <Counters data={home} /> : null}
                </div>
              )}
            </VisibilitySensor>

            <div className="row col" style={{ backgroundColor: '#090979', color: '#FFFFFF' }}>
              <div className="Homepage-highlights">
                <div className="d-flex-column">
                  <Swiper
                    modules={[Navigation, Pagination, A11y, Autoplay]}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    loop
                    autoplay={{ delay: 5000, disableOnInteraction: true }}
                  >
                    {home.highlights.map((highlight) => (
                      <SwiperSlide>
                        <Highlight key={highlight.id} data={highlight} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>

            <div className="row mt-5 justify-content-evenly" style={{ backgroundColor: '#FFF' }}>
              <div className="col-md-5 TransferCardShadow m-3" style={{ paddingTop: '20px' }}>
                <TransferList compact={2} />
              </div>

              <div className="col-md-5 TransferCardShadow m-3" style={{ paddingTop: '20px' }}>
                <div className="container d-flex-column">
                  <div className="row" style={{ "text-align": "center" }}>
                    <h2>{t("pageTitle.scouting")}</h2>
                  </div>
                </div>
                <World zoom={false} />
              </div>
            </div>

            {home.contentZone && <ComponentSwitch data={home.contentZone} />}
          </div>
          
        </div>
      </>
    );
  }
}

export default Homepage;
